import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { Modal } from 'src/components/Modal'

import Hero from './sections/hero/_index'
import PorqueAbrirContaKids from './sections/porque-abrir-conta-kids/_index'
import ComoAbrirContaKids from './sections/como-abrir-conta-kids/_index'
import InvistaDinheiroSeuFilho from './sections/invista-o-dinheiro-do-seu-filho/_index'
import CartaoDeDebito from './sections/cartao-de-debito/_index'
import NaoCobramosMensalidades from './sections/nao-cobramos-mensalidades/_index'
import ConteúdosRelacionados from './sections/conteudos-relacionados/_index'
import PerguntasFrequentes from './sections/perguntas-frequentes/_index'
import QrCodeModalContaKids from './assets/images/qr_code_site_contadigital_aquisicao_contakids.png'

import useDomReady from 'src/hooks/window/useDomReady'
import pageContext from './pageContext.json'

const ContaKids = () => {
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)

  const oneLink = 'https://inter-co.onelink.me/Qyu7/hg1cxx8y'

  const handleOpenModal = () => {
    setIsOpen(!isOpen)
  }

  const ModalContaKids = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="fs-40 lh-50 text-grayscale--500">Completa, digital e gratuita</span>'
        subtitle='<span class="fs-16 lh-19 text-grayscale--500">Para abrir sua Conta Kids, é só baixar o app e clicar em “Abrir uma Conta Gratuita”. É rápido e fácil!</span>'
        instructions='<span class="text-grayscale--500">Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o Super App do Inter.</span>'
        qrCode={QrCodeModalContaKids}
        isHrTop
        isHrBottom
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {ModalContaKids}
      <Hero openModal={handleOpenModal} oneLink={oneLink} />
      <PorqueAbrirContaKids openModal={handleOpenModal} oneLink={oneLink} />
      <ComoAbrirContaKids openModal={handleOpenModal} oneLink={oneLink} />
      <InvistaDinheiroSeuFilho openModal={handleOpenModal} oneLink={oneLink} />
      <CartaoDeDebito openModal={handleOpenModal} oneLink={oneLink} />
      <NaoCobramosMensalidades openModal={handleOpenModal} />
      <ConteúdosRelacionados />
      <PerguntasFrequentes />
    </Layout>
  )
}

export default ContaKids
